.nav-tabs .nav-link {
    padding: 17px 8px;
}

.nav-tabs .nav-link.active {
    padding: 17px 8px;
    border: none;
    border-bottom: 2px solid #0B82A2;
}

.options-container {
    display: flex;
    margin: 20px;
    gap: 20px;
}

.option-icon {
    width: 20px;
    height: 20px;
    color: #939393;
    margin-top: 20px;
    cursor: pointer;
}

.add-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #E9ECEF;
    margin: 16px 20px auto 20px;
    padding-top: 14px;
    padding-bottom: 13px;
    padding-left: 10px;
    padding-right: 10px;
}

.detail-name {
    font-weight: 500;
}

.timeline {
    position: relative;
    margin: 20px;
}

.timeline-item {
    display: flex;
    margin-bottom: 40px; 
}

.timeline-dot {
    width: 10px;
    height: 10px;
    background-color: #0B82A2; 
    border-radius: 50%;
    margin-right: 20px; 
    margin-top: 5px;
    z-index: 5;
    flex-shrink: 0;
}

.file-link>a{
    text-decoration: none;
    color: #0B82A2;
}

.file-link>a:hover{
    text-decoration: underline;
}

.timeline-content {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
}

.timeline-icons{
    display: flex;
    gap: 20px;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 4px; 
    width: 2px; 
    height: 100%;
    background-color: #D2D2D2;
    margin-top: 10px;
}

.qualification-date{
    display: flex;
    flex-direction: column;
    gap: 10;
}

.qualification{
    font-weight: 500;
}

.certificate-file{
    display: flex;
    color: #0B82A2;
    align-self: center;
    align-items: center;
    justify-content: left;
    overflow: hidden;
}

.client-options {
    padding: 20px;
    display: flex;
    gap: 20px;
    color: #939393;
}

.client-info {
    padding: 12px 30px 0px 30px;
}

.client-field {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 16px;
}

.slot-parent{
    display: grid;
    grid-template-columns: 0.5fr 0.25fr 0.25fr 1fr;
    grid-gap: 10px;
    width: 70%;
    margin-bottom: 30px;
}

.slot-parent:nth-of-type(1){
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}

.form-control.time{
    border: none;
    font-size: 13px;
    font-weight: 500;
}

.slot-day{
    font-size: 13px;
    font-weight: 400;
}