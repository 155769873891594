// Partails
@import "variables";
@import "mixins";

.main-container {
    @include flexProb(column, center, center);
    width: 100vh;
    height: 100vh;

    // Nesting
    h1 {
        color: red;

        &:hover {
            color: black;
        }

        &::after {
            content: " SCSS!";
        }
    }

    // Operators
    p {
        @include CommanCode();

        &:hover {
            color: black;
        }

        &::after {
            content: " SCSS!";
        }
    }
}