.chat-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #939393;
    border-bottom: 1px solid #939393;
}

.left-side-icons {
    display: flex;
    gap: 20px;
}

.msg-text {
    width: 80%;
    border: none;
    background: #E9ECEF;
}

.user-name {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 0px;
}

.user-role {
    color: #939393;
}

.message-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 100%;
    gap: 20px;
    justify-content: space-evenly;
    border-radius: 20px;
    background: #E9ECEF;
    height: 42px;
}

.send-button {
    align-items: center;
    background-color: #0B82A2;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: white;
    border: none;
    display: flex;
}

.send-text {
    margin-right: 20px;
}

.chat-send,
.chat-receive {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 10px 49px;
    border: 1px solid #939393;
}

.chat-send {
    margin-left: auto;
    width: max-content;
    max-width: 50%;
    border-radius: 10px 10px 0px 10px;
    background: var(--Color-8, #FFF);
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.10);
}

.chat-receive {
    margin-right: auto;
    border-radius: 0px 10px;
    background: var(--Color-8, #0B82A2);
    color: #fff;

}

.profile-details,
.profile-detail {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 16px;
}

.profile-details {
    justify-content: end;
}

.profile-detail {
    justify-content: start;
}

.profile-image {
    width: 29px;
    height: 29px;
    border-radius: 29px;
}

.text-days {
    text-align: right;
}

.receive-text .text-days {
    margin-left: auto;
}

.receive-text {
    margin-left: 20px;
    width: max-content;
    max-width: 45%;
}

.hr-text {
    padding-left: 10px;
    padding-right: 10px;
    color: #939393;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.line {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 20px auto;
    line-height: 1px;
}

.line:before,
.line:after {
    content: '';
    flex-grow: 1;
    margin: 0px 4px;
    background: linear-gradient(#939393, #939393);
    background-size: 100% 1px;
    background-position: 0% 50%;
    background-repeat: repeat-x;
}

.receive-text .message-content {
    width: max-content;
    max-width: 100%;
}

.text-container {
    display: grid;
    grid-template-rows: 1fr;
    max-height: 300px;
    overflow-y: auto;
}