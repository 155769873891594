.login-main{
    background: linear-gradient(181.27deg, #E6F4F5 1.07%, #AFD8DB 98.91%);
}
.login-main{
    padding: 60px 0px;
    min-height: 100vh;
}
.login-container {
    display: flex;
    background: #fff;
    border-radius: 20px;
}
.login-c-left {
    flex: 1;
    background: linear-gradient(180deg, #EEF7FF 0%, #EAF8F7 100%);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.login-c-right {
    flex: 1;
}
img.vector1 {
    max-width:300px;
    padding-top: 44px;
    padding-bottom: 59px;
}
.login-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
img.vector2 {
    max-width: 477px;
}
.login-form {
    padding-top: 78px;
}
.login-form h2 {
    color: #00769B;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}
.Loginerror {
    padding-bottom: 16px;
    color: #FF0000;
}
.LoginError {
    color: #FF0000;
}
img.errorIcon {
    max-width: 11px;
    margin-right: 10px;
}
.login-form form {
    margin-bottom: 92px;
}
.form-container{
    max-width: 374px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.login-form .btn {
    margin-top: 16px;
}
.f-copy.text-center{
    color: #057697;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}
span.copy-sym {
    margin-right: 16px;
}
@media only screen and (max-width: 991.98px) {
    .login-main {
        padding: 40px 0px;
    }
    
    .login-container {
        display: block;
    }
    
    .login-c-left {
        background: #fff;
        border-radius: 20px;
    }
    
    img.vector2 {
        display: none;
    }
    
    img.vector1 {
        padding-top: 40px;
        max-height: 246px;
    }
    
    .login-form {
        padding-top: 0px;
    }
    
    .f-copy.text-center {
        padding-bottom: 16px;
    }
}
@media screen and (max-width: 767.98px) {
    body {
        background: no-repeat;
    }
    
    .login-main {
        padding: 0;
    }
    
    img.vector1 {
        max-width: 186px;
    }
    .login-main{
        background: #fff;
    }
}
.alert.alert-danger.password-alert{
    margin: 30px;
}