// Mixin&&Parameters
@mixin flexProb($flex_dir,$justify,$align){
    display: flex;
    flex-direction: $flex_dir;
    justify-content: $justify;
    align-items: $align;
}
@mixin CommanCode(){
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
}
