.module-header{
    display: flex;
    background-color: #E1F1FF;
    color: #0B82A2;
    margin: 20px 20px 10px 20px;
    font-size: 13px;
    font-weight: 500;
}

.module-left{
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 15px;
}

.up-down{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #d6d6d6;
}

.module-icon{
    width: 14px;
    height: 14px;
    margin: 5px;
    cursor: pointer;
}

.submodule{
    border: 1px solid #0B82A2;
    color: #0B82A2;
    font-size: 13px;
    font-weight: 400;
    margin-left: 54px;
    margin-right: 54px;
    margin-bottom: 10px;
    display: flex;
}

.submodule .row{
    flex: 1;
    margin: 20px;
}

.submodule-line{
    text-decoration: underline;
    margin-bottom: 20px;
}

.submodule-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.submodule-actions{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.submodule-permissions{
    display: flex;
    color: #939393;
    gap: 5px;
}

.add-new{
    text-align: center;
    text-decoration: underline;
    color: #0B82A2;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}