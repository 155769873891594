.sidepanel-details {
    width: 293px;
}

.radiologist-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 100px;
    border-bottom: 0.5px solid #D9D9D9;
    font-size: 13px;
    font-weight: 600;
    width: 100%;
}

.radiologist-header.modal-header{
    padding: 13px 120px;
}

.radiologist-footer.modal-footer{
    padding: 13px 80px;
}

.radiologist-top .form-control {
    width: 90px;
    height: 30px;
}

.radiologist-detail {
    display: flex;
    padding: 10px;
    border-bottom: 0.5px solid #D9D9D9;
    width: 100%;
    justify-content: center;
    cursor: pointer;
}

.radiologist-detail.selected {
    color: white;
    background-color: #00769B;
}

.radiologist-img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid #D3D3D3;
    margin-right: 10px;
}

.radiologist-img>img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radiologist-name {
    font-size: 13px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    /* width: 105px; */
}

.radiologist-pref {
    display: flex;
    flex-wrap: wrap;
}

.pref-modality {
    margin-bottom: 0px;
    font-size: 10px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.radiologists-list {
    height: 420px;
    overflow-y: scroll;
    width: 100%;
}

.radiologists-list::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.radiologist-stats {
    margin: 0px 10px;
    width: 20px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
}

.radiologist-stats-parent{
    display: flex;
    width: 120px;
}

.radiologist-img:nth-of-type(1) {
    border: none;
}

.radiologist-modal {
    max-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 100px;
}

.online-indicator {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 0px;
    bottom: 0px;
    border-radius: 50%;
    background-color: #ddd;
}

.online-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ddd;
    /* box-shadow: 0 0 5px green; */
}

.online-indicator.online {
    background-color: green;
}

.online-indicator.offline {
    background-color: red;
}

.online-icon.online {
    background-color: green;
}

.online-icon.offline {
    background-color: red;
}