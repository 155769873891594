.AddModality-Manage {
    padding: 10px;
    background: #FFFFFF;
    border-radius: 4px;
}

.manageMain {
    margin-top: 37px;
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #CACACA;
    padding-top: 17px;
    padding-bottom: 16px;
}

.manageshow {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #5C5C5C;

}

.managenumbermain {
    width: 63px;
    height: 23px;
    border: 0.5px solid #CACACA;
    border-radius: 1px;
    margin-left: 10px;
    margin-right: 10px;
}

.managenumber {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #5C5C5C;

}

.manageimage {
    max-height: 18px;
    margin-right: 16px;
    cursor: pointer;
}

.tableheader {
    max-width: 26px;
    max-height: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #1D273B;
    background: #F0F8FF;
    border: 1px solid #CACACA;
}

.tablemargin {
    padding-top: 10px;
    padding-bottom: 10px;
}

.managePages {
    background: #F0F8FF;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #CACACA;
    padding-top: 21px;
    padding-bottom: 21px;
}

.managetabledata {
    background: #FFFFFF;
    border: 1px solid #CACACA;
    height: 56px;
}

.showingEntries {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #5C5C5C;
    height: 17px;
}

.managebutton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    margin: 34px;
    color: #CECECE;

}

.managebuttonnumber {
    width: 7px;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin-right: 16px;

    color: #5C5C5C;
}

.managefilters {
    width: 39px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;


    color: #1D273B;
}

.managefilters1 {
    width: 39px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #00769B;
    margin-left: 12px;
}

.modalinput {
    width: 136px;
    height: 49px;
    border: 1px solid #8F8F8F;
    border-radius: 8px;
    margin-bottom: 16px;
    padding-right: 16px;
}

.modalHeading{
    color: #00769B;
}

.location-icon {
    width: 10px;
    height: 10px;
    color: #5C5C5C;
}

.location-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    margin-left: 12px;
    color: #5C5C5C;

}

.location-text:hover {
    color: #00769B;
}

.tick-icon {
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.copyright-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    margin-left: 14px;
    color: #057697;
}

.users-modal {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    margin-top: 20px;
    background-color: #00769B;
}

.dashboard-2 {
    color: #057697;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.dashboard-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding-top: 8px;
    cursor: pointer;
}

.addimage {
    width: 14px;
    height: 14px;
}

.deletefields {
    width: 14px;
    height: 2px;
}

.Client-main {
    margin-top: 30px;
}

.client-sub-main {
    margin-top: 60px;
}

.upload-Download {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #00769B;
    cursor: pointer;
}

.f-copy {
    color: #057697;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: 112px;
}

.uploadBox {
    border: 1px dashed #00769B;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #00769B;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    cursor: pointer;
}

.upload-loader {
    max-width: 250px;
    max-height: 400px
}

.my-custom-scrollbar {
    position: relative;
    height: 500px;
    overflow: auto;
}

.table-wrapper-scroll-y {
    display: block;
}

.uploadBox>input {
    display: none;
}

.radioupload>input {
    display: none;
}

.radio-tags {
    margin-right: 20px
}

.upload-head {
    margin-top: 36px;
}

.radio-tags>p.radio-ptag {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
}

.radio-formcheck {
    margin-top: 48px;
}

.table-resp {
    min-height: calc(100vh - 120px);
}

.rdt_TableCol {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.search-form {
    padding: 20px 0px;
}

.searchInput{
    width: 273px;
    height: 45px;
    display: flex;
    justify-content: space-between;
}

.search-dropdowns p {
    margin-bottom: 0px;
    padding: 2px 15px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.search-dropdowns p:hover {
    background: #f4fafe;
    color: #00769B;
}

.search-dropdowns {
    border: 1px solid #ddd;
    border-top: none;
}

.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    overflow-x: auto;
}

.ClientTariff-filter {
    margin-right: 20px;
}

.accordion-container {
    margin-top: 20px;
}

.table-padding {
    border-right: 1px solid #ddd;
    padding: 34px 22px 0px 22px
}

.current-signature {
    width: 100px;
    height: 25px;
}

.ari-checbox {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}

.form-check-lable {
    display: flex;
    align-items: center;
    gap: 20px;
}

.input-container {
    border: 1px solid #ddd;
    padding: 0.375rem 0.75rem;
}

.accordion-body .container-form {
    padding-top: 0px;
}

.TariffModal {
    display: flex;
    justify-content: end;
    margin: 12px
}

.prefernce-border {
    border-bottom: 1px solid #ddd;
}

.rowsPerpage {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 4px;
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.serPerpage {
    border: none;
    color: inherit;
    background-color: transparent;
    margin-left: 8px;
    margin-right: 8px;
}

.serPerpage:hover {
    border: black;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-inline label {
    min-width: 200px;
}

.sidebar-child-container {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 1px;
}

::-webkit-scrollbar-track-piece {
    background-color: #eef7fe;
    -webkit-border-radius: 20px;
}

::-webkit-scrollbar-thumb:vertical {
    height: 50px;
    background-color: #c7e5fd;
    -webkit-border-radius: 3px;
    -webkit-border-radius: 20px;
}

::-webkit-scrollbar-thumb:horizontal {
    height: 50px;
    background-color: #c7e5fd;
    -webkit-border-radius: 3px;
    -webkit-border-radius: 20px;
}

.loader-container {
    text-align: center;
}
.invalid {
   color:var(--bs-danger-text);
    /* You can customize additional styles here based on your requirements */
}
.invalid-error{
    border: 1px solid var(--bs-danger-text);
    border-radius: 4px;
}
.loader-container img {
    width: 100px;
    padding-top: 0px;
}
.input-box{
    display:flex;
}

.input-box>div{
    flex: 1;
}
.loader-container-upload {
    text-align: center;
    width: 350px;
    padding-top: 60px;
}

.upload-Certificates {
    margin-top: 40px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-icon {
    fill: currentColor;
    cursor: pointer;
}

.table-error {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    padding-top: 60px;
    color: #6c757d;
    text-decoration-line: underline;
}

.clientbill-info {
    position: sticky;
    top: 60px;
    background-color: #EEF7FF;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #007698;
}

.tablescroll {
    max-height: calc(100vh - 400px);
    overflow-y: scroll;
    margin-top: 10px;
}

.tablescroll-tariff {
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
    margin-top: 10px;
}
.nav-tabs{
    padding-left:12px;
}
/* styles.css */

/* styles.css */

.vertical-arrow-line {
  position: relative;
  display: flex;
  flex-direction: column; /* Make it a vertical column */
  align-items: center;
  text-align: center; /* Center the text horizontally */
}

.vertical-arrow-line::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #007bff; /* Arrow color, adjust as needed */
  position: absolute;
  left: 50%; /* Center the arrow horizontally */
  top: -15px; /* Adjust arrow position as needed */
  transform: translateX(-50%);
  z-index: 1;
}

.vertical-arrow-line .line-label {
  background-color: #007bff; /* Background color for the label */
  color: #fff; /* Text color for the label */
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px; /* Adjust label position as needed */
}

  
.tablescroll-clientbill-parent{
    position: relative;
    flex: 1;
}

.tablescroll-clientbill {
    top: 10px;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    max-height: 100%;
    overflow-y: scroll;
}
.tablescroll-clientbill::-webkit-scrollbar{
    display: none;
}

.tablescroll-clientbill .table-responsive {
    border-radius: 20px;
    flex: 1;
    display: flex;
}

.tablescroll-clientbill .table-responsive .rdt_Table{
    height: 100%;
}

.tablescroll-clientbill .table-responsive .row{
    margin-right: 0px !important;
}

.copyright {
    padding-top: 20px;
    padding-bottom: 15px;
    color: #0B82A2;
    margin-bottom: 30px;
}

.sidepanel-btn {
    width: 35px;
    height: 50px;
    border-radius: 25px 0px 0px 25px;
    background: #939393;
    position: absolute;
    bottom: 30px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sidepanel-btn:hover {
    background: #414141;
    color: white;
}

.radilogist-modal {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

.table-header-menu {
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #ddd; */
    padding: 10px;
    height: 50px;
}

.table-header-menu-radio {
    display: flex;
    align-items: center;
    padding: 10px;
}

.table-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.table-responsive header {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding-bottom: 16px;
    padding-top: 16px;
    margin-bottom: 16px;
    margin-top: 32px;
}

.button-add {
    text-align: end;
    margin-top: 57px;
    padding-right: 10px;
}

.table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.padd-main {
    padding-right: 3px;
}

.sidebar-icon-table {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;

}

.sc-hLBbgP.sc-ftTHYK.ijnhsa.hFfxqh {
    display: none;
}

.table-responsive .dYXiNx, .table-responsive .gIoNnZ {
    margin-bottom: 20px;
    overflow-y: scroll;
}

.table-responsive .dYXiNx::-webkit-scrollbar, 
.table-responsive .gIoNnZ::-webkit-scrollbar,
.table-responsive .sc-dmctIk::-webkit-scrollbar{
    display: none;
}

.client-main-container {
    border-right: #8F8F8F solid 1px;
}

.client-main-container.sidepanel{
    border-right: none;
    padding: 8px;
}

.second-clientData {
    padding: 8px;
    margin-top: 48px;
    margin-left: 22px;
}

.second-clientData.sidepanel{
    margin-left: 0px;
    margin-top: 0px;
}

.worklist-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.patientdata-container {
    width: 482px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;

}

.patientdata-container-a {
    width: 356px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
}

.patientData-subcontainer {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.worklist-subtitle {
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: inline-block;
    line-height: 20px;
    cursor: pointer;
    flex: 1;
    text-align: center;
}

.worklistborder {
    border-right: solid #939393;
}

.new-worklist-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
}

.new-worklist-data {
    color: #939393;
    font-family: roboto;
    font-size: 13px;
    background-color: #D9D9D9;
    padding: 4px;
    font-weight: 400;
    border-radius: 5px;
    font-style: normal;
    line-height: 18.2px;
}

.new-worklist-stat {
    font-family: roboto;
    font-size: 13px;
    color: #FF0404;
    font-weight: 400;
    line-height: 18px;
    align-content: center;
    font-size: normal;
    display: flex;
    align-items: center;
    gap: 5px;
}

.new-worklist-under {
    font-family: roboto;
    font-size: 13px;
    color: #000000;
    font-weight: 400;
    line-height: 18px;
    font-style: normal;
}

.new-worklist-icon {
    color: #FF0404;
    align-content: center;
}

.new-worklist-icon-dropdown {
    margin-left: 3px;
    color: none;
}

.worklist-icons {
    width: 24px;
    height: 24px;
}

.image-files {
    width: 78px;
    height: 77px;
    border-radius: 5px;
    margin-right: 70px;
    margin-bottom: 42px;
}

.copy-right-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.icon-container {
    height: 50px;
    width: 35px;
    background-color: #939393;
    border-radius: 17px;
}

.tab-title {
    display: flex;
    gap: 5px;
    align-items: center;
}



.cursor-pointer {
    cursor: pointer;
}

.active-worklist-subtitle {
    display: flex;
    color: #007698;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    flex: 1;
    justify-content: center;
}
.Active-class{
    font-size: 12px;
    color: #CACACA;
    font-weight: 400;
}

.table-head-empty .rdt_TableHead {
    display: none;
}

.table-ins-name {
    padding-top: 15px;
    padding-bottom: 59px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.table-ins-2row {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.active-worklist-subtitle::after {
    content: '';
    background-color: #007698;
    border-radius: 3px 3px 0px 0px;
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 3px;
}

.worklist-subtitle span {
    margin-right: 5px;
}

.active-worklist-subtitle span {
    margin-right: 5px;
}

.serach-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
}

.table-responsive nav {
    position: absolute;
    width: 65%;
    top:0;
    margin-left: 35%;
    border-top: none;
}



.icon-image {
    color: #939393;
}

.dashboard-worklist-icon {
    display: flex;
    gap: 20px;
    align-items: center;
}

.gap-icon-worklist {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 20px 0px 0px;
    color: #939393;
}

.next-pagination {
    text-align: end;
    color: #939393;
}

.table-main-worklist {
    padding: 30px 0px 0px 20px;
    height: auto;

}

.client-worklist-main {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 20px;
}

.client-worklist-main.sidepanel{
    display: flex;
    flex-direction: column;
}

.text-style-head {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "Roboto";
    margin-bottom: 8px;
    margin-top: 8px;
}

.text-style-head-list {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Roboto";
    margin-top: 8px;
    margin-bottom: 8px;
}


.gap-icon-worklist-end {
    display: flex;
    align-items: center;
    gap: 20px;
}

.images-table {
    margin-top: 17px;
    border-radius: 17px;
    border: 1px solid #5C5C5C;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 4px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.images-count {
    color: #04B700;
}

.images-icon-attch {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;

}

.worklist-span {
    background-color: #FF0404;
    color: #FFF;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    border-radius: 6px;
    padding: 1px;
}

.study-description {
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
    text-overflow: ellipsis;
}

.stat-worklist {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.horizontalruler {
    height: 1px;
    color: solid #939393;
    margin-bottom: 0px;
}

.images-table-attach {
    border-radius: 17px;
    border: 1px solid #939393;
    padding: 5px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #939393;
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
    align-items: center;
}

.client-height {
    min-height: calc(100vh -50px);
    overflow-y: auto;
}

.table-header-main-none .rdt_TableHead {
    display: none;
}

.align-ments {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.align-ments-stat {
    color: #FF0404;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.align-ments-gen {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.toast-main {
    top: 80%;
    left: 0%;
    position: absolute;
    /* background-color: red; */
    z-index: 1;
}


.table-responsive .MHRqX input {
    display: none
}

.table-responsive.sc-hLBbgP.sc-bqWxrE.faRbsk.fFnpbF.rdt_TableCol {
    display: none;
}

.sc-hLBbgP.sc-ftTHYK {
    display: none;
}

.sc-hLBbgP.sc-bBABsx {
    display: none;
}

.sc-hLBbgP.sc-bBABsx {
    display: none;
}

.table-main {
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
}

.clientbill-info p {
    margin-bottom: 4px;
}

.form-control.form-discount {
    height: 30px;
    margin-bottom: 8px;
}

.table>thead {
    background-color: #FFF;
    color: #007698;
    text-align: center;
}

th {
    font-weight: 700;
    line-height: 24px;
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap
}

.table>tbody {
    font-size: 13px;
    font-weight: 400;
    white-space: wrap;
    text-align: center;
}

.pagination-totalpages {
    display: flex;
    flex: 1 1 auto;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    min-height: 56px;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.hOXYFc {
    margin: 0px 4px;
}

.fwhfat {
    margin: 0px 24px;
}

.bcYJHc {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    white-space: nowrap;
}

.gryXwN {
    position: relative;
    display: block;
    user-select: none;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.4s ease 0s;
    color: rgba(0, 0, 0, 0.54);
    fill: rgba(0, 0, 0, 0.54);
    background-color: transparent;
}

.billingModality {
    /* display: flex;
    gap: 10px;
    margin-bottom: 20px; */
    color: rgba(0, 0, 0, 0.54);
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
}

.action-title {
    font-weight: bold;
    font-size: 8px;
    line-height: 20px;
}

.select-invoice:hover {
    border: 1px solid #007698;
}

.total-amount {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 12px;
    line-height: 20px;
}

.net-amount {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 12px;
    line-height: 20px;
}

.role-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #00769B;
}

.Crud-role {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #00769B;
    line-height: 20px;
}

.role-menu-header {
    display: flex;
    background: var(--Color-12, #E1F1FF);
    align-items: center;
    padding-top: 13px;
    padding-left: 13px;
    padding-bottom: 13px;
}

.role-main-heading {
    margin-top: 20px;
}

.role-check {
    border: 1.5px solid #00769B;
}

.role-actions {
    padding-left: 13px;
    padding-top: 13px;
    border: 2px solid #EEF7FF;
    margin-bottom: 10px;
}

.icon-select {
    margin-left: 360px;
}

.clientinformation {
    border-bottom: rgba(0, 0, 0, 0.54);
    ;
}

.table tbody tr td.table-subheaders-left {
    text-align: left;
    white-space: wrap;
    max-width: 100px;

}

.table thead tr th.table-subheaders-left {
    text-align: left;
    white-space: wrap;
    align-items: center;

}

.table tbody tr td.table-subheaders-right {
    text-align: right;
    white-space: wrap;
    max-width: 100px;

}

.search-icon1 {
    width: 24px;
    height: 24px;
}

.styles-country-list {
    justify-content: center;
}

ul.instructions li {
    font-size: 14px;
}

.table-top {
    margin-top: 10px;
}

.actions-button {
    display: flex;
    align-items: center;
    gap: 10px;
}

.aaa {
    position: absolute;
    top: 0;
    left: 100%;
    /* Position content outside the cell */
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px;
    white-space: nowrap;
    display: none;
}

.row:hover .aaa {
    display: block;
    /* Show content on hover */
}

.nav-custom-table {
    margin-top: 30px;
}

.tab-content{
    padding-bottom: 12px;
}

.nav-tabs-exp {
    margin-left: 30px;
}

.nav-tabs .nav-link.active {
    color: #0B82A2;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 18px 16px 18px 16px;
}

.nav-tabs .nav-link {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 18px 16px 16px 18px;
}

.prefrence-paddin {
    padding: 12px 30px 0px 30px;
}

.prefrence-paddin-add {
    padding: 16px 80px 0px 80px;
    /* display: flex; */
}

/* .submodality-dir {
    width: 469px;
    height: 45px;
} */

.chec-margin {
    margin-bottom: 30px;
    margin-top: 10px;
}

.right-arrow-button::after {
    content: '';
    width: 9px;
    height: 9px;
    border-right: 2px solid white;
    transform: rotate(315deg);
    border-bottom: 2px solid white;
    font-size: 24px;
    flex-shrink: 0;
    margin-left: 61px;
}

.left-arrow-button::before {
    content: '';
    width: 9px;
    height: 9px;
    border-right: 2px solid white;
    transform: rotate(135deg);
    border-bottom: 2px solid white;
    font-size: 24px;
    flex-shrink: 0;
    margin-right: 61px;
}

button.right-arrow-button {
    border-radius: 5px;
    background: #0B82A2;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 14px 13px;
}

button.left-arrow-button {
    border-radius: 5px;
    background: #0B82A2;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 14px 13px;
}

button.right-arrow-button:hover::after {
    border-right: 2px solid #0B82A2;
    border-bottom: 2px solid #0B82A2;
}

button.left-arrow-button:hover::before {
    border-right: 2px solid #0B82A2;
    border-bottom: 2px solid #0B82A2;
}

.button-left-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-right {
    display: flex;
    justify-content: end;
    align-items: center;
}

.form-group-pages {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* width: 200px; */
}
.gap_modality{
    gap: 40px;
}
.service-group-form {
    margin-top: 10px;
    padding: 12px;
    background: #FFF;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25), -1px -1px 10px 0px rgba(0, 0, 0, 0.25);

}
.upload-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border: 2px dashed #0b82a2;
    border-radius: 10px;
}
.image-upload {
    display: none;
}
.drag-drop {
    color: #0b82a2;
    width: 115px;
    height: 115px;
    margin-bottom: 16px;
    margin-top: 15px;
}
.file-text {
    color: #0b82a2;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 14px;
}
.attachment-layout{
    margin-bottom: 30px;
}
.cons-fomr {
    height: calc(100vh - 550px);
    overflow-y: scroll;
}
.postion-finish{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;

    
}
.style-edit-worklist{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
}
.percentage-bar{
    gap: 5px;
}
.report-view{
    color: rgba(0,0,0,0.87);
    text-decoration: none;
}
/* 
.dataTableContainer {
    width: 100%;
    overflow-x: auto;
}


.dataTableContainer .dataTable th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dataTableContainer .dataTable td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media (max-width: 768px) {

    .dataTableContainer .dataTable th,
    .dataTableContainer .dataTable td {
        white-space: nowrap;
    }
} */