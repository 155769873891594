.flexible-column-container {
    display: flex;
    gap: 30px;
    width: 100%;
    padding-top: 10px;
}

.flexible-sub-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flexible-sub-top {
    /* display: flex;
    gap: 30px;
    flex: 1; */
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 4fr 3fr 3fr 2fr 7fr;
    width: 100%;
}

.hover-icons {
    display: flex;
    gap: 20px;
    justify-content: end;
}

.flexible-sub-bottom {
    display: flex;
}

.client-name {
    font-size: 13px;
    font-weight: 600;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.client-institution {
    font-size: 13px;
    font-weight: 400;
    width: 150px;
    color: #939393;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .case-status-parent {
    width: 50px;
} */

.case-status {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    color: #939393;
    border-radius: 2px;
    background: #D9D9D9;
    flex-shrink: 0;
    height: fit-content;
    width: max-content;
}

.patient-img {
    width: 18px;
    height: 18px;
}

.patient-name {
    display: flex;
    font-size: 13px;
    /* max-width: 150px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.patient-study {
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.stat {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    color: red;
}

.set-as {
    width: 69px;
    height: 20px;
    border-radius: 5px;
    border: 0.25px solid black;
    background: #D9D9D9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
}

.receive-time {
    color: red;
}

.receiving-date {
    font-size: 13px;
    font-weight: 600;
    /* max-width: 60px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.patient-attachment {
    border-radius: 17px;
    border: 1px solid #939393;
    color: #939393;
    padding: 4px 15px;
    margin: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

/* Home Health dashboard css starts here */

.cards-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    color: white;
}

.card {
    flex: 1;
    height: 100px;
    background-color: #3dd9d8;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0px;
    border: none;
}

.card:nth-of-type(2) {
    background-color: #37c8ef;
}

.card:nth-of-type(3) {
    background-color: #2b91d5;
}

.card:nth-of-type(4) {
    background-color: #004aac;
}

.card-content {
    display: flex;
    gap: 30px;
    align-items: baseline;
}

.card-content p,
.card-content h2 {
    margin-bottom: 0px;
}

.filters {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.filter {
    display: flex;
    align-items: center;
    gap: 5px;
}

.period {
    background-color: #e7e7e7;
    color: #408083;
    padding: 3px 10px;
    border-radius: 20px;
    cursor: pointer;
}

.period:hover,
.period.active {
    background-color: #408083;
    color: white;
}

.btn-add-new {
    background-color: #408083;
    color: white;
    padding: 3px 15px;
    border-radius: 20px;
}

.btn-add-new:hover {
    background-color: white;
    color: #408083;
}

.modal-backdrop {
    background-color: white;
    opacity: 0.5 !important;
}

.add-new-modal {
    width: 95%;
    max-width: none;
    color: #408083;
}

.add-new-modal .modal-header {
    width: 100%;
}

.add-new-modal .modal-content {
    padding: 0px 50px;
    border-radius: 60px;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 25px 35px -15px;
}

.add-new-modal .modal-title {
    font-size: 20px;
    font-weight: 700;
    flex: 1;
}

.add-new-modal .modal-body{
    padding: 0px 0px 30px 0px;
}

.form-title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 20px;
}

.search {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 10px;
    border: 1px solid black;
    border-radius: 20px;
    background-color: white;
}

.search input {
    border: none;
    outline: none;
    text-align: center;
}

.actions {
    position: relative;
}

.actions-modal .modal-content {
    position: absolute;
}

.custom-dropdown-menu {
    padding: 20px;
    border-radius: 50px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.custom-dropdown-item {
    color: #408083;
    font-size: 13px;
}

.custom-dropdown-item:hover {
    font-weight: 600;
    color: #408083;
}

.close {
    text-align: end;
}

.custom-dropdown-item:focus,
.custom-dropdown-item:active,
.custom-dropdown-item:focus-visible {
    outline: none;
    box-shadow: none;
    background-color: inherit;
    /* Retain the original background color */
    color: #408083;
    /* Retain the original text color */
}

/* .new_rdt {
    padding: 20px;
    background-color: #ffffff;
}

.new_rdt .rdt_TableRow {
    border-bottom: 2px solid #e7e7e7 !important;
}

.new_rdt .rdt_TableHead {
    border-bottom: 2px solid white !important;
} */

.new-form .form-control {
    border: none;
    border-bottom: 1px solid #408083;
    border-radius: 0px;
    color: #408083;

    &::placeholder {
        color: #408083;
    }
}

.new-form .form-control:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #408083;
}

/* Target the dropdown container */
.custom-select-container .custom-select__control {
    border: none;
    border-bottom: 1px solid #408083;
    border-radius: 0px;
    outline: none;
    box-shadow: none;
}

/* Target the dropdown options */
.custom-select-container .custom-select__option {
    color: #408083;
}

.custom-select-container .custom-select__option--is-selected {
    background-color: #408083;
    color: white;
}

/* Target the placeholder */
.custom-select-container .custom-select__placeholder {
    color: #408083;
}

/* Target the single value (the selected option displayed) */
.custom-select-container .custom-select__single-value {
    color: #408083;
}

.submit-new {
    background-color: #408083;
    color: white;
    border-radius: 5px;
    padding: 2px 10px;
}

.rowBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowCenter {
    display: flex;
    align-items: center;
}

.w-95{
    width: 95%;
}

/* Home Health dashboard css ends here */